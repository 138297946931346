import { render, staticRenderFns } from "./CalendarWorker.vue?vue&type=template&id=201ca267&scoped=true"
import script from "./CalendarWorker.vue?vue&type=script&lang=js"
export * from "./CalendarWorker.vue?vue&type=script&lang=js"
import style0 from "@quasar/quasar-ui-qcalendar/dist/index.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@quasar/quasar-ui-qiconpicker/dist/index.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./CalendarWorker.vue?vue&type=style&index=2&id=201ca267&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201ca267",
  null
  
)

export default component.exports